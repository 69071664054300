
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { emptyToLine } from "@/utils/common";
import { Table, TableRowsSelect } from "@/utils/decorator";
import SwitchStatus from "@/components/scope/switch-status.vue";
import StandardFlag from "@/components/scope/standard-flag.vue";
import t from "@common/src/i18n/t";

const detecationItem = namespace("detecationItem");
const base = namespace("base");
@Component({
  components: { SwitchStatus, StandardFlag }
})
@Table("loadListData")
@TableRowsSelect({
  warningMsg: t("setting.select-test-item"),
  updateStatusFuncName: "confirmUpdateStatus",
  tableRefKey: "table"
})
export default class TestingItemList extends Vue {
  @detecationItem.Action maintainCalendarProjectPage;
  @detecationItem.Action maintainCalendarUpdateProjectState;
  searchForm = {
    projectName: ""
  };
  emptyToLine = emptyToLine;
  maintainProjectList: any[] = [];

  get searchList() {
    return [
      {
        label: "项目名称",
        type: "input",
        value: "",
        prop: "name"
      }
    ];
  }

  handleSearch(data) {
    this.mix_pageNum = 1;
    data.forEach(item => {
      if (item.prop) {
        this.searchForm[item.prop] = item.value;
      }
    });
    this.loadListData();
  }

  confirmUpdateStatus(state, selectedRow) {
    if (1 == selectedRow.state) {
      return this.maintainCalendarUpdateProjectState({
        projectNo: selectedRow.projectNo,
        state: 0
      }).then(data => {
        this.$message.success(status ? t("setting.enable-successful") : t("setting.disable-success"));
        this.loadListData();
      });
    } else if (0 == selectedRow.state) {
      return this.maintainCalendarUpdateProjectState({
        projectNo: selectedRow.projectNo,
        state: 1
      }).then(data => {
        this.$message.success(status ? t("setting.enable-successful") : t("setting.disable-success"));
        this.loadListData();
      });
    }
  }

  created() {
    this.init();
  }

  @Table.LoadDataMethod()
  loadListData() {
    return this.maintainCalendarProjectPage({
      ...this.searchForm,
      current: this.mix_pageNum,
      size: this.mix_pageSize
    }).then(data => {
      this.maintainProjectList = data.data.list;
      return data;
    });
  }

  async init() {
    this.loadListData();
  }

  handleEdit(row) {
    this.$router.push(`/maintenance/maintainProject/item/add?projectNo=${row.projectNo}`);
    // this.$router.push(`/maintenance/maintainProject/item/add/${row.projectNo}`);
  }
}
